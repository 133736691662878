import {Component, inject} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {FooterComponent} from "../../ui/footer/footer.component";
import {HeaderComponent} from "../../ui/header/header.component";
import {RouterOutlet} from "@angular/router";
import {ReferenceService} from "../../data-access/http/reference.service";
import {filter, switchMap, takeUntil} from "rxjs/operators";
import {UserStateModel} from "../../data-access/state/user.state";
import {EquipeGlobaleDomainService} from "../../../mon-equipe/domain/equipeglobale.domain";
import {CollaborateurDomainService} from "../../../mon-equipe/domain/collaborateur.domain";
import {Observable, Subject} from "rxjs";
import {UserDomainService} from "../../domain/user.domain";
import {DataImmuablesDomainService} from "../../domain/data-immuables.domain";
import {BreadcrumbsModule} from "@exalif/ngx-breadcrumbs";

@Component({
  selector: 'app-app-layout',
  standalone: true,
    imports: [
        AsyncPipe,
        FooterComponent,
        HeaderComponent,
        RouterOutlet,
        NgIf,
        BreadcrumbsModule
    ],
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss'
})
export class AppLayoutComponent {

    public versionBack$
    private referenceService = inject(ReferenceService)

    private equipeGlobaleDomainService = inject(EquipeGlobaleDomainService)
    private collaborateurDomainService = inject(CollaborateurDomainService)
    errorStore$: Observable<any> = this.collaborateurDomainService.errorSystem$
    private userDomainService = inject(UserDomainService)
    user$: Observable<any> = this.userDomainService.user$
    private unsubscribe$ = new Subject<void>()
    private dataImmuablesDomain = inject(DataImmuablesDomainService)

    constructor() {
        this.user$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((user: UserStateModel) => {
                    return user.isAuthenticated === true
                }),
                switchMap(() => this.dataImmuablesDomain.setEntiteAffectation()), // load entite affectation
                switchMap(() => this.collaborateurDomainService.loadingMonEquipe()), // load mon equipe
                switchMap(() => this.equipeGlobaleDomainService.loadEquipeGlobale()), // load Equipe Globale
                switchMap(() => this.versionBack$ = this.referenceService.getVersionBack()) // load version back
            )
            .subscribe({
                next: () => this.collaborateurDomainService.setErrorSystem(false),
                error: () => {
                    this.collaborateurDomainService.setLoading(false)
                    this.collaborateurDomainService.setErrorSystem(true)
                }
            })

    }


}
