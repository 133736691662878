import {Router} from '@angular/router'
import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {combineLatest, Observable, Subject} from 'rxjs'
import {filter, switchMap, take, takeUntil, tap} from 'rxjs/operators'
import {AsyncPipe, NgFor, NgIf} from '@angular/common'
import {DataTableColsDescription, DataTableComponent, DataTableOptions} from "../../../shared/ui/data-table/data-table.component";
import {CustomModalComponent} from "../../../shared/ui/custom-modal/custom-modal.component";
import {CollaborateurDto} from "src/app/shared/models/collaborateursDto";
import {CollaborateurDomainService} from "../../domain/collaborateur.domain";
import {EquipeGlobaleDomainService} from "../../domain/equipeglobale.domain";
import {ToastDomainService} from "../../../shared/ui/toast/domain/toast.domain";

@Component({
    selector: 'app-equipe-directe',
    templateUrl: './equipe-directe.component.html',
    styleUrls: ['./equipe-directe.component.scss'],
    standalone: true,
    imports: [NgIf, DataTableComponent, CustomModalComponent, NgFor, AsyncPipe],
})
export class EquipeDirecteComponent implements OnInit, OnDestroy {
    // Show sort buttons and head for first array
    public tableOptionsMonEquipe: DataTableOptions
    // Don't show sort buttons and head for second array
    public tableOptionsAVenir: DataTableOptions
    public collabsNumber: number = 0
    // matricule of last collab added,  for scrolling to
    public matriculeToScrollTo: string = ''
    public showValidateButton: boolean = false
    // remove a collab variables
    public confirmRemoveModalIsVisible: boolean = false
    public confirmAddModalIsVisible: boolean = false
    public modalTitleRemove: string = 'Confirmation de suppression'
    public modalTitleAdd: string = "Confirmez l'ajout de ces collaborateurs dans <br />votre équipe ?"
    public collabMatriculeToRemove: string = ''
    public prenomCollaborateur: string = ''
    public nomCollaborateur: string = ''
    public collabsSelectedName: Array<string> = []
    private equipeGlobaleDomainService = inject(EquipeGlobaleDomainService)
    private collaborateurDomainService = inject(CollaborateurDomainService)
    monEquipeActuelleSorted$: Observable<Array<CollaborateurDto>> = this.collaborateurDomainService.monEquipeActuelleSorted$.pipe(tap(console.log))
    monEquipeAVenir$: Observable<CollaborateurDto[]> = this.collaborateurDomainService.monEquipeAVenir$
    monEquipeSortingParams$: Observable<any> = this.collaborateurDomainService.monEquipeSortingParams$
    collaborateursLoading$: Observable<boolean> = this.collaborateurDomainService.collaborateurStateLoading$
    collaborateursSelected$: Observable<Array<CollaborateurDto>> = this.collaborateurDomainService.selectedCollaborateurs$
    private toastDomainService = inject(ToastDomainService)
    private unsubscribe$ = new Subject<void>()

    constructor(private router: Router) {
        // Description of table "Mon Equipe" (rows and cols propoerties ie name, corresponding field, and field to sort on)
        const colNamesMonEquipe: Array<DataTableColsDescription> = [
            {
                action: 'show',
                name: 'Collaborateurs',
                fields: ['nomCollaborateur', 'prenomCollaborateur'],
                sortField: 'nomCollaborateur',
                className: 'added-recently',
                thClassName: 'cellWidth-1',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Emploi',
                fields: ['nomEmploi'],
                sortField: 'nomEmploi',
                className: 'added-recently',
                thClassName: 'cellWidth-1',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Direction / agence / service',
                fields: ['nomEntiteAffectation'],
                sortField: 'nomEntiteAffectation',
                className: 'added-recently',
                thClassName: 'cellWidth-1',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Dans mon équipe depuis',
                fields: ['dateDebutEquipe'],
                sortField: 'dateDebutEquipe',
                className: 'added-recently',
                tooltip: '',
            },
        ]

        this.tableOptionsMonEquipe = {
            showSorting: true,
            showHead: true,
            headClassName: 'light-head-table',
            noData: 'Aucun collaborateur dans votre équipe actuellement',
            containerClassName: 'container-fixed-height1',
            cols: colNamesMonEquipe,
            tableId: 'monEquipe',
            rows: {
                action: 'removeItem',
                className: 'row-highlighted',
                tooltip: '',
                conditionField: 'datefilled',
                conditionValue: true,
                fieldForAction: 'matriculeCollaborateur',
            },
        }

        // Description of columns for table "A Venir"
        const colNamesAVenir: Array<DataTableColsDescription> = [
            {
                action: 'show',
                name: 'Collaborateurs',
                fields: ['nomCollaborateur', 'prenomCollaborateur'],
                sortField: 'nomCollaborateur',
                className: 'added-recently',
                thClassName: 'cellWidth-1',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Emploi',
                fields: ['nomEmploi'],
                sortField: 'nomEmploi',
                className: 'added-recently',
                thClassName: 'cellWidth-1',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Direction / agence / service',
                fields: ['nomEntiteAffectation'],
                sortField: 'nomEntiteAffectation',
                className: 'added-recently',
                thClassName: 'cellWidth-1',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Date de mouvement',
                fields: ['mouvementDateEffet'],
                sortField: 'mouvementDateEffet',
                className: 'added-recently text-center',
                tooltip: "Date prévue d'entrée <br />ou de sortie de l'équipe",
            },
            {
                action: 'show',
                name: 'Mouvement',
                fields: ['mouvement'],
                sortField: 'mouvement',
                className: 'added-recently icon-td',
                tooltip:
                    '<i class="bi bi-box-arrow-in-left in-color"></i> Entrées prochaines <br /> \
       <i class="bi bi-box-arrow-right out-color"></i> Sorties prochaines<br />',
            },
        ]
        this.tableOptionsAVenir = {
            showSorting: false,
            showHead: true,
            headClassName: 'light-head-table',
            noData: 'Aucun mouvement de collaborateur prévu prochainement',
            cols: colNamesAVenir,
            tableId: 'monEquipeAVenir',
            rows: {
                action: 'removeItem',
                className: 'row-highlighted',
                tooltip: '',
                conditionField: 'datefilled',
                conditionValue: true,
                fieldForAction: 'matriculeCollaborateur',
            },
        }
    }

    ngOnInit(): void {
        combineLatest([this.monEquipeActuelleSorted$, this.monEquipeAVenir$])
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((mesEquipes) => {
                    const monEquipe = mesEquipes[0]
                    const monEquipeAVenir = mesEquipes[1]

                    !!monEquipe && monEquipe.length ? (this.collabsNumber = monEquipe.length) : 0

                    if ((!!monEquipe && monEquipe.length) || (!!monEquipeAVenir && monEquipeAVenir.length)) {
                        this.showValidateButton =
                            !!monEquipe?.find((collabs) => collabs.datefilled === true) ||
                            !!monEquipeAVenir?.find((collabs) => collabs.datefilled === true)
                    }
                    // scroll to last collab added
                    this.collaborateursSelected$
                        .pipe(
                            filter((collabs: Array<CollaborateurDto>) => collabs && collabs?.length > 0),
                            take(1),
                            tap((collabs) => {
                                if (collabs.length > 0) {
                                    this.matriculeToScrollTo = 'm' + collabs[collabs.length - 1]?.matriculeCollaborateur
                                }
                            })
                        )
                        .subscribe()

                    // Init search filters
                    this.collaborateurDomainService.searchReset()
                })
            )
            .subscribe()
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    public sortDataOn(event: any) {
        this.collaborateurDomainService.sortMonEquipe(event)
    }

    public addNewCollaborateur() {
        this.router.navigate(['/mon-equipe/nouveau-collaborateur'])
    }

    /**
     * Click on button 'remove selected collab' :
     *  - Search collab in store
     *  - Get prenom and nom for the confirmation modale.
     * @param event : matricule
     */
    public clickOnRemoveCollaborateur(event: any) {
        this.collabMatriculeToRemove = event.valueForAction
        this.confirmRemoveModalIsVisible = true
        this.collaborateursSelected$
            .pipe(
                take(1),
                tap((collabs) => {
                    const collabFound = collabs.find(
                        (collab) => collab.matriculeCollaborateur === this.collabMatriculeToRemove
                    )
                    if (collabFound) {
                        this.prenomCollaborateur = collabFound.prenomCollaborateur
                        this.nomCollaborateur = collabFound.nomCollaborateur
                    }
                    this.toastDomainService.removeToastMessage()
                })
            )
            .subscribe()
    }

    /**
     *  Remove collaborateur : after confirmation in modale :
     *  - remove collab in store (sliced from collaborateursSelected)
     *  - close modale
     *  - reset search
     * @param matricule
     */
    public removeCollab(matricule: string) {
        console.log("removeCollab ==> " + matricule)

        if (matricule) {
            this.collaborateurDomainService.removeCollab(matricule)

            this.collaborateurDomainService.searchReset()
        }
        this.confirmRemoveModalIsVisible = false
    }

    /**
     *  DOnnées pour la modale de confirmation d'ajout des nouveaux collabs
     *  - dans les selectedCOllaborateurs du store, on prend ceux qui ont un status selected et une date
     * pour afficher leur nom et prenom dans la modale.
     *
     * @param none
     */
    public showModalAddCollaborateurs() {
        this.collabsSelectedName = []
        const selectedCollabs = this.collaborateurDomainService.selectedCollaborateurs()
        selectedCollabs.forEach((item: any) => {
            if (item.datefilled && item.selected) {
                this.collabsSelectedName.push(item.nomCollaborateur + ' ' + item.prenomCollaborateur)
            }
        })
        this.confirmAddModalIsVisible = true
        this.toastDomainService.removeToastMessage()
    }

    /**
     *  Validate and add collaborateurs in dataBase
     *  - do Action from Store
     *
     * @param none
     */
    public validateAddCollaborateurs() {
        this.collaborateurDomainService.addSelectedCollabsInMyTeamAction()
            .pipe(
                switchMap((_) => this.equipeGlobaleDomainService.loadEquipeGlobale()), // reload equipeGlobale
                tap((_) => {
                    this.matriculeToScrollTo = ''
                    this.confirmAddModalIsVisible = false
                    this.collaborateurDomainService.searchReset() // reset du search
                })
            )

            .subscribe(


            )
    }
}
