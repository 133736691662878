import {PaginationDto} from 'src/app/shared/models/paginationDto'
import {DataTableColsDescription, DataTableComponent, DataTableOptions} from '../data-table/data-table.component'
import {CommonService} from 'src/app/shared/data-access/http/common.service'
import {NavigationExtras, Router} from '@angular/router'
import {Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {combineLatest, forkJoin, Observable, Subject} from 'rxjs'
import {debounceTime, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators'
import {CollaborateurDto} from 'src/app/shared/models/collaborateursDto'
import {modalConfigModel} from 'src/app/shared/ui/custom-modal/custom-modal.component'
import {CollaborateursService} from 'src/app/shared/data-access/http/collaborateurs.service'
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms'
import {CollaborateurBackDto, CollaborateurBackFullDto} from 'src/app/shared/models/collaborateursBackDto'
import {environment} from '../../../../environments/environment'
import * as moment from 'moment'
import {TypeToast} from '../toast/state/toast.state'
import {ComiteMembreService} from 'src/app/shared/data-access/http/comite-membre.service'
import {ComiteListComponent} from 'src/app/comites/feature/comite-list/comite-list.component'
import {AddCollabComponent} from '../add-collab/add-collab.component'
import {NgSelectModule} from '@ng-select/ng-select'
import {CustomModalComponent} from '../custom-modal/custom-modal.component'
import {PaginationComponent} from '../pagination/pagination.component'
import {AsyncPipe, NgClass, NgFor, NgIf} from '@angular/common'
import {CollaborateurDomainService} from "../../../mon-equipe/domain/collaborateur.domain";
import {SortOrSearchObject} from "../../../mon-equipe/data-access/state/collaborateurs.state";
import {ToastDomainService} from "../toast/domain/toast.domain";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        DataTableComponent,
        PaginationComponent,
        CustomModalComponent,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgClass,
        NgFor,
        AddCollabComponent,
        AsyncPipe,
        BsDatepickerModule,
    ],
})
export class ListComponent implements OnInit, OnDestroy {

    @Input()
    provenance!: string
    @Input()
    comiteListComponent!: ComiteListComponent

    @ViewChild('dp', { static: false }) dp!: ElementRef

    clicked: boolean = false
    removeClicked: boolean = false
    public startManagerCalendar = this.getStartManagerCalendar()
    public endManagerCalendar = this.getEndManagerCalendar()
    public managerFormIsSubmitting: boolean = false
    public managerForm: FormGroup = this.fb.group({
        searchCollaborateur: [null, Validators.required],
        dateMajManager: [null, Validators.required],
    })
    public managers: CollaborateurBackDto[] = []
    public majManager = false
    public resultsNumber: number = 0
    public modalIsVisible: boolean = false
    public modalTitle: string = ''
    public resultsToShow$!: Observable<any>
    comiteMembreLoading$!: Observable<boolean>
    public tableOptionsResults: DataTableOptions
    public tableOptionsComite: DataTableOptions
    public tableOptionsAddComite: DataTableOptions
    public modalConfig: modalConfigModel = {keyboard: true}
    private toastDomainService = inject(ToastDomainService)
    private collaborateurDomainService = inject(CollaborateurDomainService)
    collaborateursLoading$: Observable<boolean> = this.collaborateurDomainService.collaborateurStateLoading$
    searchResults$: Observable<Array<CollaborateurDto>> = this.collaborateurDomainService.collaborateurSearchResults$
    searchResultsSortingParams$: Observable<any> = this.collaborateurDomainService.monEquipeSearchResultsSortingParams$
    collaborateursFilters$: Observable<any> = this.collaborateurDomainService.filters$
    selectedCollaborateurs$: Observable<Array<CollaborateurDto>> = this.collaborateurDomainService.selectedCollaborateurs$
    pagination$: Observable<PaginationDto> = this.collaborateurDomainService.searchResultPagination$
    private unsubscribe$ = new Subject<void>()

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private commonService: CommonService,
        private comiteMembreService: ComiteMembreService,
        private collaborateurService: CollaborateursService
    ) {
        // Description of columns for table "Mon Equipe" (name, corresponding field, and field to sort on)
        const colNamesSearchResults: Array<DataTableColsDescription> = [
            {
                action: 'show',
                name: 'Collaborateurs',
                fields: ['nomCollaborateur', 'prenomCollaborateur'],
                sortField: 'nomCollaborateur',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Emploi',
                fields: ['nomEmploi'],
                sortField: 'emploi.nomEmploi',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Direction / agence / service',
                fields: ['nomEntiteAffectation'],
                sortField: 'entite.nomEntiteAffectation',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Manager actuel',
                fields: ['nomSuperieurHierarchique', 'prenomSuperieurHierarchique'],
                sortField: 'relation.managerHierarchique.nomCollaborateur',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: "Dans l'équipe depuis",
                fields: ['dateDebutEquipeAffichage'],
                sortField: 'dateDebutEquipeAffichage',
                className: 'text-center',
                tooltip: '',
            },
            {
                action: 'checkbox',
                name: 'Ajouter',
                fields: ['selected'], // for action's cell : only one field in array (action=checkbox).
                sortField: undefined,
                className: 'text-center',
                tooltip: '',
            },
        ]
        this.tableOptionsResults = {
            showSorting: true,
            showHead: true,
            headClassName: 'light-head-table',
            noData: 'Aucun collaborateur trouvé. Veuillez modifier vos critères de recherche.',
            containerClassName: 'container-fixed-height2',
            rows: {
                action: 'clickOnRow',
                className: 'cursor-pointer',
                tooltip: '',
                conditionField: 'hasFuturManager',
                conditionValue: false,
                fieldForAction: '',
            },
            cols: colNamesSearchResults,
        }

        // Table en bas de la page ajout / suppression de membre de comité

        this.tableOptionsComite = {
            showSorting: true,
            showHead: true,
            headClassName: 'light-head-table',
            containerClassName: 'container-fixed-height2',
            rows: {
                action: 'clickOnRow2',
                className: 'cursor-pointer',
                tooltip: '',
                conditionField: 'hasFuturManager',
                conditionValue: false,
                fieldForAction: '',
            },
            cols: colNamesSearchResults,
        }

        // Tableau dans la popup en cliquant sur "Ajouter dans le comité"

        const colNamesComite: Array<DataTableColsDescription> = [
            {
                action: 'show',
                name: 'Collaborateurs',
                fields: ['nomCollaborateur', 'prenomCollaborateur'],
                sortField: 'nomCollaborateur',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Entité',
                fields: ['nomEntiteAffectation'],
                sortField: 'entite.nomEntiteAffectation',
                className: '',
                tooltip: '',
            },
            {
                action: 'show',
                name: 'Emploi',
                fields: ['nomEmploi'],
                sortField: 'emploi.nomEmploi',
                className: '',
                tooltip: '',
            },
        ]
        this.tableOptionsAddComite = {
            showSorting: false,
            showHead: true,
            headClassName: 'light-head-table',
            containerClassName: 'container-fixed-height2',
            cols: colNamesComite,
        }
        this.comiteMembreLoading$ = new Observable<boolean>((subscriber) => subscriber.next(false))
    }

    ngOnInit(): void {
        // stay as observable to be sent to data-table components.
        // Merge data of searchResultSorted and selectedCollaborateurs to show if collab has already been checked or not
        this.resultsToShow$ = combineLatest([this.searchResults$, this.selectedCollaborateurs$]).pipe(
            takeUntil(this.unsubscribe$),
            map(([searchResults,selectedCollaborateurs]) => {
                if (searchResults && searchResults.length > 0 && selectedCollaborateurs && selectedCollaborateurs.length > 0) {
                    return searchResults.map((result) => {
                        const found = selectedCollaborateurs.find(
                            (bb) => result['matriculeCollaborateur'] === bb['matriculeCollaborateur']
                        )
                        if (found) {
                            result = found
                        }
                        return result
                    })
                }
                else {
                    if (searchResults) {
                        return searchResults
                    } else {
                        return []
                    }
                }
            })
        )

        // Subscribe to this observable to get the numbers of items
        this.pagination$
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((results) => {
                    if (!!results && results.totalElements) {
                        this.resultsNumber = results.totalElements
                    } else {
                        this.resultsNumber = 0
                    }
                })
            )
            .subscribe()

        this.majManager = localStorage.getItem('majManager') === 'true'
        this.selectedCollaborateurs$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((collabs) => collabs && collabs.length > 0),
                tap((collabs) => {
                    this.modalTitle = 'Sélectionner ' + (this.majManager ? 'un manager' : "une date d'effet")
                    collabs.length < 2
                        ? (this.modalTitle += ' pour ce collaborateur')
                        : (this.modalTitle += ` pour ces ${collabs.length} collaborateurs`)
                })
            )
            .subscribe()
    }

    public sortDataOn(event: any) {
        // Si recherche contient "pasDeManager" coché (on regarde dans le store), et si tri sur ManagerNom, on ne fait rien
        const searchFields = this.collaborateurDomainService.searchCollabs()?.searchFields
        const found = searchFields.find((item: any) => item.field === 'pasDeManager' && item.value === true)
        if (event.field === 'relation.managerHierarchique.nomCollaborateur' && found !== undefined) {
            // on ne fait pas de tri
        } else {
            this.collaborateurDomainService.sortSearch(event)
        }
    }

    /**
     *  receiving event from clickEvent(data-table component)
     *  add or remove the received item from store
     */
    public addOrRemoveCollaborateur(event: any) {
        const collabSelected = event.object
        const collab = {
            ...collabSelected,
            dateDebutEquipe: '',
            dateFinEquipe: '',
            [event.actionFieldName]: event.actionFieldValue,
        }
        if (event.actionFieldValue === true) {
            this.collaborateurDomainService.addCollab(collab)
        } else {
            this.collaborateurDomainService.toggleSelectCollabInSearch(collab.matriculeCollaborateur, false)
            this.collaborateurDomainService.removeCollab(collab.matriculeCollaborateur)
        }
    }

    /**
     * Add selected collabs in store selectedCollabs (no date and no validation)
     * Call add-collab component (modal and date selection)
     */
    public updateMyTeam() {
        // Open popup with collabsToAdd in array
        this.modalIsVisible = true
    }

    public updateMyComite() {
        this.modalIsVisible = true
        this.modalTitle = `Ajouter des membres au comité ${this.comiteListComponent.codeComite}`
    }

    public removeCollaborateur(event: any) {
        this.collaborateurDomainService.removeCollab(event.object.matriculeCollaborateur)
        this.removeClicked = true

        this.selectedCollaborateurs$
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((collabs) => {
                    if (collabs.length === 0 && this.removeClicked) {
                        this.backFromModale(false)
                    }
                })
            )
            .subscribe(() => (this.removeClicked = false))
    }

    onSubmitComite() {
        this.modalIsVisible = false
        this.clicked = true

        this.selectedCollaborateurs$
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((collabs) => {
                    const matricules = collabs.map((collab) => collab.matriculeCollaborateur)
                    if (matricules.length > 0 && this.clicked) {
                        this.comiteMembreService
                            .addMembre(this.comiteListComponent.codeComite, matricules)
                            .subscribe((logs) => {
                                if (logs.status === 'KO') {
                                    let errors: Array<string>
                                    const prefixe = 'Le collaborateur '

                                    errors = logs.logs
                                        .filter((log) => log.startsWith('ERROR'))
                                        .map((log) =>
                                            log.substring(
                                                log.indexOf(prefixe) + prefixe.length,
                                                log.indexOf(prefixe) + prefixe.length + 5
                                            )
                                        )

                                    if (errors.length > 0) {
                                        this.toastDomainService.addToastMessage(
                                            TypeToast.danger,
                                            `Ajout de membres au comité ${this.comiteListComponent.codeComite}`,
                                            `<br/>Collaborateurs déjà membre du comité : ${errors.join(', ')}`,
                                            undefined
                                        )
                                    }

                                    errors = logs.logs
                                        .filter((log) => log.startsWith('FATAL'))
                                        .map((log) => log.substring(log.indexOf('[') + 1, log.indexOf(']')))

                                    if (errors.length > 0) {
                                        this.toastDomainService.addToastMessage(
                                            TypeToast.danger,
                                            `Ajout de membres au comité ${this.comiteListComponent.codeComite}`,
                                            `<br/>Une erreur est survenue lors de l'ajout des collaborateurs : ${errors.join(
                                                ', '
                                            )}`,
                                            undefined
                                        )
                                    }
                                }
                                this.comiteListComponent.getMembresComite(this.comiteListComponent.codeComite)
                            })
                    }
                })
            )
            .subscribe(() => (this.clicked = false))

        this.collaborateurDomainService.resetSelectedCollabs()
        this.collaborateurDomainService.searchReset()
    }

    /**
     * just validate new collabs (date is filled, datefilled is true )
     * back to "equipe directe"
     * @param event boolean :
     */
    public backFromAddCollab(event: boolean) {

        this.modalIsVisible = event
        this.router.navigate(['.//mon-equipe'])
    }

    /**
     *  If modal is closed without doing nothing : réinit selectedCollaborateurs.
     * @param event: boolean
     */
    public backFromModale(event: boolean) {
        this.managerForm.reset()
        this.managers = []
        this.collaborateurDomainService.resetSelectedCollabs()
        this.collaborateurDomainService.searchReset()
        this.modalIsVisible = event
    }

    public backFromModaleEnTantQueManager(event: boolean) {
        this.managerForm.reset()
        this.managers = []
        // this.collaborateurDomainService.resetSelectedCollabs()
        this.collaborateurDomainService.searchReset()
        this.modalIsVisible = event
    }

    /**
     * Pagination
     */
    public changePage(pageNumber: number) {
        this.collaborateurDomainService.paginate(pageNumber, 'searchCollabs').subscribe()
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    onSubmitManagers() {
        const selectedCollaborateurs = this.collaborateurDomainService.selectedCollaborateurs()
        const updateManagers$ = []

        for (const collab of selectedCollaborateurs){
            updateManagers$.push(
                this.collaborateurDomainService.updateManagerCollaborateur(collab.matriculeCollaborateur,
                    this.managerForm.get('searchCollaborateur')?.value?.matriculeCollaborateur,
                    moment(this.managerForm.get('dateMajManager')?.value).format('YYYY-MM-DD'))
            )
        }

        forkJoin(updateManagers$).subscribe( () => {
                /* SFE 2024-07 */
                this.backFromModaleEnTantQueManager(false)
                this.reloadRoute(this.router, ['/managers/update'])
                this.managerFormIsSubmitting = false
                this.toastDomainService.addToastMessage(
                    TypeToast.success,
                    'Ajout Manager',
                    `Le collaborateur a été ajouté à l'équipe de ${localStorage.getItem('add_manager_as_managername')}`
                )
                // SFE 2024-09
                /*
                this.reloadRoute(this.router, ['/valideurs/changement-manager'])
                */

            }
        )

/*
        this.selectedCollaborateurs$
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((collabs) => {
                    this.managerFormIsSubmitting = true
                    collabs.forEach((collab) => {
                        const newRelation = {
                            matriculeCollaborateur: collab.matriculeCollaborateur,
                            matriculeManagerHierarchique: this.managerForm.get('searchCollaborateur')?.value?.matriculeCollaborateur,
                            dateDebut: moment(this.managerForm.get('dateMajManager')?.value).format('YYYY-MM-DD'),
                        }
                        this.collaborateurService.addCollaborateur(newRelation).subscribe(() => {
                            // SFE 2024-07
                            this.backFromModaleEnTantQueManager(false)

                            this.reloadRoute(this.router, ['/valideurs/changement-manager'])

                            this.managerFormIsSubmitting = false
                            this.toastDomainService.addToastMessage(
                                TypeToast.success,
                                'Ajout Manager',
                                `Le collaborateur '${collab.prenomCollaborateur} ${
                                    collab.nomCollaborateur
                                }' a été ajouté à l'équipe de ${localStorage.getItem('add_manager_as_managername')}`
                            )
                        })
                    }

                    )
                })
            )
            .subscribe(

            )

 */
    }

    onSearchManagers($event: any) {
        if ($event.term?.trim().length < 3) {
            this.managers = []
            this.managerForm.patchValue({searchCollaborateur: null})
        } else {
            const potentialMatricule: string[] = $event.term.split(' ').filter((text: string) => /^\d+$/.test(text))

            const potentialNames: string[] = $event.term.split(' ').filter((text: string) => !/^\d+$/.test(text))

            const searchFields: Array<SortOrSearchObject> = []
            searchFields.push({field: 'matricule', value: potentialMatricule.join(' ')})
            searchFields.push({field: 'collaborateur', value: potentialNames.join(' ')})
            searchFields.push({field: 'searchForManager', value: true})
            this.collaborateurService
                .searchCollaborateurs(
                    environment.collaborateurApi,
                    searchFields,
                    {
                        pageNumber: 0,
                        pageSize: 20,
                        totalElements: 20,
                        totalPages: 20,
                    },
                    undefined
                )
                .pipe(debounceTime(300))
                .subscribe((data: CollaborateurBackFullDto) => {
                    if (data?.content) {
                        this.managers = data.content
                        if (
                            !this.managers.some(
                                (manager) =>
                                    manager.matriculeCollaborateur ===
                                    this.managerForm.get('searchCollaborateur')?.value?.matriculeCollaborateur
                            )
                        ) {
                            this.managerForm.patchValue({searchCollaborateur: null})
                        }
                    } else {
                        this.managers = []
                        this.managerForm.patchValue({searchCollaborateur: null})
                    }
                })
        }
    }

    onChangeManagers($event: any) {
        if ($event) {
            this.managers = this.managers.filter(
                (value) => value.matriculeCollaborateur === $event.matriculeCollaborateur
            )
            localStorage.setItem(
                'add_manager_as_managername',
                $event.prenomCollaborateur + ' ' + $event.nomCollaborateur
            )
        } else {
            this.managers = []
            localStorage.setItem('add_manager_as_managername', '')
        }
    }

    reloadRoute(router: Router, route: any[], extras?: NavigationExtras) {
        router.routeReuseStrategy.shouldReuseRoute = () => false
        router.onSameUrlNavigation = 'reload'
        router.navigate(route, extras)
    }

    private getStartManagerCalendar(): Date {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), 1)
    }

    private getEndManagerCalendar(): Date {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth() + 2, 0)
    }

    private formatDate(date: Date): string {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    openDatepicker() {
        this.dp.nativeElement.click()
    }

}
